import { createStackNavigator } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { IntroductionScreen } from "../components";
import { BottomTabNavigator } from "./BottomTabNavigator";
import { useLocation, useUser } from "hooks";
import { LoginMandatoryStackNavigator } from "./LoginMandatoryStackNavigator";
import * as SplashScreen from "expo-splash-screen";
import { Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { LocationScreen } from "screens";
import useTheme from "theme/ThemeProvider";
import useConfiguration from "../configuration/ConfigurationProvider";

const ParamsStack = createStackNavigator();

export const RootStack: React.FC = () => {
  const { t } = useTranslation();
  const {
    displayIntroduction,
    refreshUser,
    refreshAnonymousUser,
    user,
    isRefreshFetching,
    isLoginMandatory,
    isUserAllowed,
    /* isAppLoadedAndDisplayable, */
    /* appHasLoadOnce, */
  } = useUser();
  const { isLocatedOnce } = useLocation();
  const { configuration } = useConfiguration();

  const { theme } = useTheme();

  const [isFirstLoadFinished, setIsFirstLoadFinished] =
    useState<boolean>(false);

  useEffect(() => {
    const getUser = async () => {
      if (!(await refreshUser())) {
        await refreshAnonymousUser();
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isFirstLoadFinished &&
      !isRefreshFetching /* &&
      displayIntroduction !== null
      // Commenté ici car lorsque les pages d'intro n'ont pas été lues, displayIntroduction est 'toujours' à false,
      // et donc le premier chargement de la page n'est jamais terminé (isFirstLoadFinished).
      */
    ) {
      setIsFirstLoadFinished(true);
    }
  }, [isRefreshFetching, displayIntroduction, isFirstLoadFinished]);

  const WelcomeStack = createStackNavigator<any>();
  const LocationStack = createStackNavigator<any>();
  const showLoginStack = isLoginMandatory && (!user || !isUserAllowed());

  /* useEffect(() => {
    if (!isFirstLoadFinished && isAppLoadedAndDisplayable) {
      setIsFirstLoadFinished(true);
    }
  }, [isAppLoadedAndDisplayable, isFirstLoadFinished]); */

  useEffect(() => {
    const checkIfCanRemoveSplashScreen = async () => {
      if (isFirstLoadFinished && Platform.OS !== "web") {
        await SplashScreen.hideAsync();
      }
    };
    checkIfCanRemoveSplashScreen();
  }, [isFirstLoadFinished]);

  return !isFirstLoadFinished /* &&
  !appHasLoadOnce */ ? null : displayIntroduction !== false ? (
    <WelcomeStack.Navigator>
      <WelcomeStack.Screen
        name={"introduction"}
        component={IntroductionScreen}
        options={{
          headerShown: false,
          title:
            t("header.welcomeApp", {
              name: theme.texts.appName,
            }) ?? "",
        }}
      />
    </WelcomeStack.Navigator>
  ) : !isLocatedOnce && configuration.sites.features.locationMandatory ? (
    <LocationStack.Navigator>
      <LocationStack.Screen
        name={"location"}
        component={LocationScreen}
        options={{
          headerShown: false,
          title: t("header.location"),
        }}
      />
    </LocationStack.Navigator>
  ) : showLoginStack ? (
    <ParamsStack.Navigator>
      <ParamsStack.Screen
        name={"params"}
        component={LoginMandatoryStackNavigator}
        options={{
          headerShown: false,
        }}
      />
    </ParamsStack.Navigator>
  ) : (
    <BottomTabNavigator defaultHome="news" />
  );
};
